//for action and buttons both
export var labels={
    action_refresh:{
        en:'Refresh',
        bn:'রিফ্রেশ'
    }, 
    action_back:{
        en:'Back',
        bn:'পেছনে'
    }, 
    action_new:{
        en:'New',
        bn:'নতুন'
    }, 
    action_edit:{
        en:'Edit',
        bn:'সম্পাদন'
    }, 
    action_print:{
        en:'Print',
        bn:'ছাপান'
    }, 
    action_csv:{
        en:'CSV',
        bn:'সিএসভি'
    }, 
    action_search:{
        en:'Search',
        bn:'অনুসন্ধান'
    }, 
    action_0:{
        en:'View',
        bn:'দেখা'
    }, 
    action_1:{
        en:'Add',
        bn:'যোগ'
    },
    action_2:{
        en:'Edit',
        bn:'সম্পাদন'
    },
    action_3:{
        en:'Delete',
        bn:'মুছে ফেলা'
    },
    action_4:{
        en:'Forward',
        bn:'অগ্রবর্তী'
    },
    action_5:{
        en:'Print',
        bn:'ছাপান'
    },
    action_6:{
        en:'CSV',
        bn:'সিএসভি'
    },
    action_7:{
        en:'Search',
        bn:'অনুসন্ধান'
    },
    action_8:{
        en:'Column Control',
        bn:'কলাম নিয়ন্ত্রণ'
    },
}